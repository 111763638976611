import type {
  UseInfiniteQueryOptions,
  QueryKey,
  InfiniteData,
  UseInfiniteQueryReturnType,
} from "@tanstack/vue-query";
import { useInfiniteQuery } from "@tanstack/vue-query";
import type { NuxtError } from "nuxt/app";

type UseInfiniteQuerySuccessReturnType<TData, TError> =
  UseInfiniteQueryReturnType<TData, TError> & {
    data: globalThis.Ref<InfiniteData<TData>>;
    isLoading: false;
    isSuccess: true;
    status: "success";
  };

export const useSuspenseInfiniteQuery = async <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<
    UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "enabled"
  >,
) => {
  const { $queryClient } = useNuxtApp();
  const query = useInfiniteQuery({ ...options, queryClient: $queryClient });

  if (!query.isSuccess.value) {
    try {
      await query.suspense();
    } catch (e) {
      const error = e as NuxtError;
      error.fatal = true;
      throw createError(error);
    }
  }

  return query as UseInfiniteQuerySuccessReturnType<TData, TError>;
};
